<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown">
    <v-card class="my-5">
      <v-card-text class="py-10"
        :class="$vuetify.breakpoint.smAndDown ? 'px-5' : 'px-10'"
        v-html="templates.terms_of_use"
      ></v-card-text>
    </v-card>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import { API_ENDPOINT } from '@/literals.js'
import { ApiTool, CheckTokenError } from '@/module.js'

export default {
  components: {
  },

  props: {
  },

  data() {
    return {
      templates: {terms_of_use: ''},
      publicApi: new ApiTool(API_ENDPOINT + '/'),
    };
  },

  computed: {
  },

  created() {
    this.getData()
  },

  methods: {
    getData() {
      this.setTemplateStrings()
    },

    setTemplateStrings() {
      this.publicApi.getReqSystemPublic('template-string/', {usedIn: '', itemName: 'terms_of_use'}).then( results => {
        if (!results || !results.length) return

        results.map( template => {
          this.templates[template.item_name] = template.content
        })
      })
      .catch(error => CheckTokenError(error))
    },
  }
}
</script>

<style scoped>
</style>
